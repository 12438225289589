.nav-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 8px 16px;
  border-bottom: 1px solid #0000008a;
  background: var(--light-primary-contrast, #fff);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.left-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-content {
  display: flex;
  align-items: center;
}

.right-content-image {
  padding: 12px;
  background: none;
  border: none;
}
.left-content-buttons {
  display: flex;
  justify-content: space-between;
  width: 342px;
  margin-left: 50px;
}

.nav-left-button {
  color: rgba(0, 0, 0, 0.6);
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46000000834465027px;
  text-align: left;
}
