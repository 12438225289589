.dropdown-container {
  margin-top: 10px;
  border-radius: 8px;
  background: var(--light-background-paper, #fff);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  width: 600px;
  position: fixed;
  top: 56px;
  right: 25px;
  z-index: 1000;
}

.dropdown-title {
  color: var(--light-text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.4%;
  /* 32.016px */
  height: 32px;
  padding: 16px;
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #0000008a;
}

.dropdown-item-container {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  height: 48px;
  background: none;
  border: none;
  width: 100%;
}

.dropdown-item-button {
  text-decoration: none;
  display: block;
  width: 100%;
}

.dropdown-item-container:not(:last-child) {
  border-bottom: 1px solid #0000008a;
}

.dropdown-items {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.dropdown-item-text {
  color: var(--light-text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.dropdown-item-left-content {
  display: flex;
  gap: 22px;
}
